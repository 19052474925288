export default function NumberFormat ( para )
{
	const currency_symbol = "₺";
	const formattedOutput = new Intl.NumberFormat ( 'tr-TR', {
		style                 : 'currency',
		currency              : 'TRY',
		minimumFractionDigits : 2,
	} );

	if (isNaN(para)) {
		return 0;
	}
	
	return formattedOutput.format ( para ).replace ( currency_symbol, '');
}